export default [
  {
    inputs: [],
    name: 'RECEIVE_WITH_AUTHORIZATION_TYPEHASH',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bool', name: 'isScwWallet', type: 'bool' },
      { internalType: 'uint32', name: 'minGasLimit', type: 'uint32' },
      { internalType: 'address', name: 'connector', type: 'address' },
      {
        components: [
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'uint256', name: 'validAfter', type: 'uint256' },
          { internalType: 'uint256', name: 'validBefore', type: 'uint256' },
          { internalType: 'bytes32', name: 'nonce', type: 'bytes32' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct LyraForwarderBase.ReceiveWithAuthData',
        name: 'authData',
        type: 'tuple',
      },
    ],
    name: 'depositUSDCSocketBridge',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const
