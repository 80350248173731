import { TaskState } from '@gelatonetwork/relay-sdk/dist/lib/status/types'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import { Hash } from 'viem'

import {
  BRIDGE_GAS_SUBMITTED_STATUSES,
  BridgeTransaction,
  DepositTransaction,
  gelatoRelay,
  MAXIMUM_L1_SPONSORED_DEPOSITS,
  MAXIMUM_L2_SPONSORED_DEPOSITS,
} from '../constants/bridge'
import { DepositNetwork, mainnetChain } from '../constants/chains'
import { USDC_DECIMALS } from '../constants/contracts'
import { depositTokenConfig, DepositTokenId } from '../constants/tokens'
import { getDepositNetworkForChainId } from './chains'
import sleep from './sleep'
import { getIsL2 } from './tokens'

type GelatoBalance = {
  id: string
  totalSpentAmount: string
  totalDepositedAmount: string
  totalWithdrawnAmount: string
  updatedAt: string
  createdAt: string
  remainingBalance: string
}

type GelatoBalanceResponse = {
  sponsor: {
    address: string
    mainBalance: GelatoBalance
    balances: GelatoBalance[]
  }
}

export type GelatoNotSponsoredReason =
  | 'not-supported'
  | 'recent-deposit'
  | 'min-amount'
  | 'gelato-empty'
  | 'sponsor-helper-empty'

export type GelatoSponsoredResponse =
  | { isSponsored: true; notSponsoredReason?: undefined; lastDepositTimestamp?: undefined }
  | {
      isSponsored: false
      notSponsoredReason: GelatoNotSponsoredReason
      lastDepositTimestamp?: number
    }

const POLLING_INTERVAL_MS = 1000

const getMinimumGelatoBalance = (network: DepositNetwork) => {
  switch (network) {
    case DepositNetwork.Base:
    case DepositNetwork.Arbitrum:
    case DepositNetwork.Optimism:
      return toBigNumber(10, USDC_DECIMALS)
    case DepositNetwork.Ethereum:
      return toBigNumber(100, USDC_DECIMALS)
  }
}

export const fetchIsEligibleForSponsoredBridge = async (
  network: DepositNetwork,
  token: DepositTokenId,
  _recentDeposits: BridgeTransaction[],
  amount: bigint
): Promise<GelatoSponsoredResponse> => {
  /**
   * User is eligible for sponsorship if:
   * 1. Gelato is supported for token
   * 2. No deposits within threshold
   * 3. Amount is above min sponsored amount
   * 4. Gelato has sufficient balance
   */

  // 1. Gelato is supported for token
  if (!getIsGelatoBridgeSupported(network, token)) {
    return {
      isSponsored: false,
      notSponsoredReason: 'not-supported',
    }
  }
  // Filter for txs with sponsored gas (successful or reverted)
  const recentSponsoredDeposits = _recentDeposits.filter(
    (deposit) =>
      deposit.type === 'deposit' &&
      deposit.bridgeToLyra.isSponsored &&
      BRIDGE_GAS_SUBMITTED_STATUSES.includes(deposit.bridgeToLyra.status)
  ) as DepositTransaction[]

  const sponsoredDepositsFromL1 = recentSponsoredDeposits.filter(
    (deposit) => deposit.bridgeToLyra.srcChainId === mainnetChain.id
  )
  const sponsoredDepositsFromL2 = recentSponsoredDeposits.filter((deposit) => {
    const network = getDepositNetworkForChainId(deposit.bridgeToLyra.srcChainId)
    return network ? getIsL2(network) : false
  })
  /**
   * 2. No. deposits less than limit within time threshold
   *    - 5 deposits from L2s or 1 deposit from L1
   * */
  const isMaxDepositsWithinTimeThreshold =
    (network === DepositNetwork.Ethereum &&
      sponsoredDepositsFromL1.length >= MAXIMUM_L1_SPONSORED_DEPOSITS) ||
    (getIsL2(network) && sponsoredDepositsFromL2.length >= MAXIMUM_L2_SPONSORED_DEPOSITS)

  const lastDepositTimestamp: number | undefined = recentSponsoredDeposits.sort(
    (a, b) => b.timestamp - a.timestamp
  )[0]?.timestamp

  if (isMaxDepositsWithinTimeThreshold) {
    return {
      isSponsored: false,
      notSponsoredReason: 'recent-deposit',
      lastDepositTimestamp,
    }
  }

  // 3. Amount is above min sponsored amount
  const isAmountSponsored = amount >= getGelatoMinDepositForSponsorship(network, token)
  if (!isAmountSponsored) {
    return {
      isSponsored: false,
      notSponsoredReason: 'min-amount',
    }
  }

  // 4. Gelato has sufficient balance
  // Note: gelato is denominated in USDC on polygon
  const isGelatoBalanceSolvent = await fetchIsGelatoBalanceSolvent(network)
  if (!isGelatoBalanceSolvent) {
    return {
      isSponsored: false,
      notSponsoredReason: 'gelato-empty',
    }
  }

  return {
    isSponsored: true,
  }
}

const fetchIsGelatoBalanceSolvent = async (network: DepositNetwork) => {
  try {
    const res = await fetch(
      'https://api.gelato.digital/1balance/networks/mainnets/sponsors/0xd1E1B0cbeA0CFF6d8287d87C7bBA62067a81911C'
    )
    const sponsorBalanceRes: GelatoBalanceResponse = await res.json()
    return sponsorBalanceRes.sponsor.balances.some(
      (balance) => BigInt(balance.remainingBalance) > getMinimumGelatoBalance(network)
    )
  } catch (e) {
    console.warn('Gelato balance fetch failing')
    return false
  }
}

export const getGelatoMinDepositForSponsorship = (
  network: DepositNetwork,
  token: DepositTokenId
): bigint => {
  // Note: sponsor $100 on L2, $1000 on L1
  return getIsL2(network)
    ? depositTokenConfig[token].l2MinSponsoredAmount
    : depositTokenConfig[token].l1MinSponsoredAmount
}

export const getIsGelatoBridgeSupported = (
  network: DepositNetwork,
  token: DepositTokenId
): boolean => {
  const config = depositTokenConfig[token]
  const depositGelatoNetworks = config.gelatoNetworks
  return !!depositGelatoNetworks && depositGelatoNetworks.includes(network)
}

export const waitForRelayerTxHash = async (taskId: string, timeoutMs = 60_000): Promise<Hash> => {
  if (timeoutMs <= 0) {
    throw new Error('Gelato transaction timed out')
  }
  const status = await gelatoRelay.getTaskStatus(taskId)
  console.debug('polling relayer status', status)
  const txCancelled =
    status?.taskState === TaskState.Cancelled || status?.taskState === TaskState.ExecReverted
  if (txCancelled) {
    throw new Error('Gelato transaction cancelled or reverted')
  }
  // Wait for transaction to be posted on-chain
  if (!status || !status.transactionHash || (!txCancelled && !status.blockNumber)) {
    await sleep(POLLING_INTERVAL_MS)
    return waitForRelayerTxHash(taskId, timeoutMs - POLLING_INTERVAL_MS)
  }
  return status.transactionHash as Hash
}
