import { BatchUserOperationCallData } from '@alchemy/aa-core'
import { CallWithSyncFeeERC2771Request, ERC2771Type } from '@gelatonetwork/relay-sdk'
import { TaskState } from '@gelatonetwork/relay-sdk/dist/lib/status/types'
import { MAX_INT } from '@lyra/core/constants/contracts'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import filterNulls from '@lyra/core/utils/filterNulls'
import formatQueryParams from '@lyra/core/utils/formatQueryParams'
import permitSelfPayingForwarderAbi from '@lyra/web/abis/permitSelfPayingForwarderAbi'
import permitSponsoredForwarderAbi from '@lyra/web/abis/permitSponsoredForwarderAbi'
import selfPayingForwarderAbi from '@lyra/web/abis/selfPayingForwarderAbi'
import sponsoredForwarderAbi from '@lyra/web/abis/sponsoredForwarderAbi'
import vaultAbi from '@lyra/web/abis/vaultAbi'
import { Address, encodeFunctionData, Hash, hexToSignature, keccak256, toHex } from 'viem'

import DepositHelperAbi from '../abis/DepositHelperAbi'
import erc20Abi from '../abis/erc20Abi'
import vaultOldAbi from '../abis/vaultOldAbi'
import withdrawalHelperAbi from '../abis/withdrawHelperAbi'
import { KytError } from '../constants/auth'
import {
  BRIDGE_FAILED_STATUSES,
  BridgeContractAddresses,
  BridgeTransaction,
  BridgeTransactionStatus,
  BridgeWithdrawContractAddresses,
  bridgeWithdrawContractAddresses,
  BridgeWithdrawContractHookAddresses,
  externalDepositContractAddresses,
  GELATO_MESSAGE_COMPLETE_STATUSES,
  GELATO_MESSAGE_FAILED_STATUSES,
  gelatoRelay,
  RelayerStatus,
  SOCKET_BRIDGE_L2_GAS_LIMIT,
  SOCKET_MESSAGE_API_URL,
  SOCKET_MESSAGE_COMPLETE_STATUSES,
  SOCKET_MESSAGE_STATUSES,
  SOCKET_TX_API_URL,
  socketDepositContractAddresses,
  SocketMessageIdStatusResponse,
  SocketStatusResponse,
  socketWithdrawContractAddresses,
} from '../constants/bridge'
import { arbitrumChain, baseChain, DepositNetwork, mainnetChain } from '../constants/chains'
import { lyraClient } from '../constants/client'
import { isMainnet, isTestnet } from '../constants/env'
import { depositTokenConfig, DepositTokenId, ETH_ADDRESS, TokenId } from '../constants/tokens'
import { TransactionOptions } from '../constants/transactions'
import { HELP_BRIDGE_URL, HELP_ENABLE_SPENDING_URL } from '../constants/urls'
import { User } from '../constants/user'
import { LyraWalletClient } from '../constants/wallet'
import { DepositQuote } from '../hooks/useDepositQuote'
import { getChainForDepositNetwork } from './chains'
import { getGelatoMinDepositForSponsorship, waitForRelayerTxHash } from './gelato'
import { getNetworkClient } from './rpc'
import {
  formatDepositTokenBalance,
  formatDepositTokenSymbol,
  formatSocketDepositTokenSymbol,
  formatSocketTokenSymbol,
  getDepositTokenAddress,
  getLyraTokenAddress,
} from './tokens'
import { coerce } from './types'
import { fetchScwAddress, getTransactionDisabledMessage, walletClientToSigner } from './wallet'

export type BridgeResponse = {
  isNative: boolean
  isSponsored: boolean
  txHash: Hash
  amount: bigint
  fees: bigint
}

export type BridgeOptions =
  | {
      isNative: true
      isMaxApproval: boolean
    }
  | {
      isNative: false
      isSponsored: boolean
      maxFee: bigint
    }

export const bridgeToLyraChain = async (
  walletClient: LyraWalletClient,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  bridgeOptions: BridgeOptions,
  options: TransactionOptions
): Promise<BridgeResponse> => {
  const chain = getChainForDepositNetwork(network)

  if (chain.id !== walletClient.chain.id) {
    throw new Error(`Not connected to ${network} network`)
  }
  if (bridgeOptions.isNative) {
    return await nativeBridgeToLyraChain(
      walletClient,
      network,
      token,
      amount,
      bridgeOptions.isMaxApproval,
      options
    )
  } else {
    return await gelatoBridgeToLyraChain(
      walletClient,
      network,
      token,
      amount,
      bridgeOptions.isSponsored,
      bridgeOptions.maxFee,
      options
    )
  }
}

const nativeBridgeToLyraChain = async (
  walletClient: LyraWalletClient,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  isMaxApproval: boolean,
  options: TransactionOptions
): Promise<BridgeResponse> => {
  const chain = getChainForDepositNetwork(network)

  const ownerAddress = walletClient.account.address

  const { socketVault, socketDepositFastConnector, nativeHelper, isNewBridge } =
    getDepositContractAddresses(network, token)

  if (!nativeHelper) {
    throw new Error()
  }

  // Deposit via socket vault directly if:
  // - is new Socket connector OR
  // - depositing USDT (requires special approval flow which have bricked/will brick the helper contracts)
  const toApprove = isNewBridge || token === DepositTokenId.USDT ? socketVault : nativeHelper

  const networkClient = await getNetworkClient(network)
  const tokenAddress = getDepositTokenAddress(network, token)

  if (isMainnet) {
    // !!IMPORTANT
    // Screen native deposits BEFORE transaction is executed
    console.debug('screening wallet')
    const screenRes = await fetch('/api/deposit/native/screen', {
      method: 'POST',
      body: JSON.stringify({
        amount: toHex(amount),
        network,
        token,
      }),
    })

    if (!screenRes.ok) {
      if (screenRes.status === 500) {
        throw new Error('Wallet screening failed. Please try again.')
      } else if (screenRes.status === 403) {
        throw new KytError(getTransactionDisabledMessage('kyt'))
      } else {
        throw new Error('Deposit screening failed')
      }
    }
  }
  const [allowance, socketEthFee] = await Promise.all([
    // Note: no allowance for native ETH deposits
    token !== DepositTokenId.ETH
      ? networkClient.readContract({
          abi: erc20Abi,
          address: tokenAddress,
          functionName: 'allowance',
          args: [ownerAddress, toApprove],
        })
      : undefined,
    fetchSocketBridgeFee(network, token),
  ])

  // Note: reset approval for USDT (unique requirement of USDT)
  if (token === DepositTokenId.USDT && allowance && allowance < amount) {
    options.onTransactionStatusChange('confirm', {
      title: `Enable Spending ${formatDepositTokenSymbol(token)} on Derive`,
      contextLink: {
        href: HELP_ENABLE_SPENDING_URL,
        label: 'Why is this required?',
        target: '_blank',
      },
    })
    const approveHash = await walletClient.writeContract({
      address: tokenAddress,
      abi: erc20Abi,
      functionName: 'approve',
      args: [toApprove, BigInt(0)],
    })
    const approveReceipt = await networkClient.waitForTransactionReceipt({ hash: approveHash })
    if (approveReceipt.status === 'reverted') {
      throw new Error('Approve transaction reverted')
    }
  }

  if (allowance !== undefined && allowance < amount) {
    console.debug('insufficient allowance', { allowance, amount })

    options.onTransactionStatusChange('confirm', {
      title: `Enable Spending ${formatDepositTokenSymbol(token)} on Derive`,
      contextLink: {
        href: HELP_ENABLE_SPENDING_URL,
        label: 'Why is this required?',
        target: '_blank',
      },
    })

    // Note: use permit signature for assets that support permit
    const approveHash = await walletClient.writeContract({
      address: tokenAddress,
      abi: erc20Abi,
      functionName: 'approve',
      args: [toApprove, isMaxApproval ? MAX_INT : amount],
    })

    options.onTransactionStatusChange('in-progress', {
      title: `Enabling Spending ${formatDepositTokenSymbol(token)} on Derive`,
    })

    const approveReceipt = await networkClient.waitForTransactionReceipt({ hash: approveHash })
    if (approveReceipt.status === 'reverted') {
      throw new Error('Approve transaction reverted')
    }

    console.debug('approval receipt', approveReceipt)
  }

  options.onTransactionStatusChange('confirm', {
    title: `Confirm Deposit ${formatDepositTokenBalance(amount, token)}`,
    context: `Deposits typically take ${getBridgeDurationEstimate(network)}.`,
    contextLearnMoreHref: HELP_BRIDGE_URL,
  })

  let txHash: Hash

  const gasLimit = BigInt(SOCKET_BRIDGE_L2_GAS_LIMIT)

  if (token === DepositTokenId.ETH) {
    // native token, wrap into wETH then deposit
    console.debug('submitting native ETH deposit', {
      socketVault,
      gasLimit,
      socketDepositFastConnector,
      value: amount + socketEthFee,
    })
    txHash = await walletClient.writeContract({
      address: nativeHelper,
      abi: DepositHelperAbi,
      functionName: 'depositETHToLyra',
      args: [socketVault, true /* isSCW */, gasLimit, socketDepositFastConnector],
      value: amount + socketEthFee, // ETH value to be transferred
    })
  } else {
    console.debug('submitting native deposit', {
      tokenAddress,
      socketVault,
      amount,
      gasLimit,
      socketDepositFastConnector,
      value: socketEthFee,
    })
    if (isNewBridge) {
      // SUPER IMPORTANT!! must be smart contract wallet address
      const receiver = await fetchScwAddress(ownerAddress)
      txHash = await walletClient.writeContract({
        address: socketVault,
        abi: vaultAbi,
        functionName: 'bridge',
        args: [receiver, amount, gasLimit, socketDepositFastConnector, '0x', '0x'],
        value: socketEthFee,
      })
    } else if (token === DepositTokenId.USDT) {
      // SUPER IMPORTANT!! must be smart contract wallet address
      const receiver = await fetchScwAddress(ownerAddress)
      txHash = await walletClient.writeContract({
        address: socketVault,
        abi: vaultOldAbi,
        functionName: 'depositToAppChain',
        args: [receiver, amount, gasLimit, socketDepositFastConnector],
        value: socketEthFee,
      })
    } else {
      txHash = await walletClient.writeContract({
        address: nativeHelper,
        abi: DepositHelperAbi,
        functionName: 'depositToLyra',
        args: [
          tokenAddress,
          socketVault,
          true /* isSCW */,
          amount,
          gasLimit,
          socketDepositFastConnector,
        ],
        value: socketEthFee,
      })
    }
  }

  options.onTransactionStatusChange('in-progress', {
    txHash,
    chain,
    title: `Depositing ${formatDepositTokenBalance(amount, token)}`,
  })

  // Note: only wait for tx receipt for ETH deposits to subtract fee
  // Avoids risk of user closing modal and subaccount deposit not being queued
  const receipt = await networkClient.waitForTransactionReceipt({ hash: txHash })
  if (receipt.status === 'reverted') {
    throw new Error('Transaction reverted')
  }

  // socket fee
  const fees = socketEthFee

  const apiParams = {
    amount: toHex(amount),
    network,
    token,
    transactionHash: txHash,
  }

  const depositRes = await fetch('/api/deposit/native', {
    method: 'POST',
    body: JSON.stringify(apiParams),
  })

  if (!depositRes.ok) {
    throw new Error(
      'Deposit was successful but Derive failed to log your transaction. Please contact support.'
    )
  }

  if (!options.skipCompleteStatus) {
    options.onTransactionStatusChange('complete', {
      txHash,
      chain,
      title: `Successfully Deposited ${formatDepositTokenBalance(amount, token)}`,
      context: `Your ${formatDepositTokenSymbol(
        token
      )} will be available in your Funding Account in ${getBridgeDurationEstimate(network)}.`,
    })
  }

  return { isNative: true, isSponsored: false, txHash, amount, fees }
}

const gelatoBridgeToLyraChain = async (
  walletClient: LyraWalletClient,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  isSponsored: boolean,
  maxFee: bigint,
  options: TransactionOptions
) => {
  const chain = getChainForDepositNetwork(network)
  if (walletClient.chain.id !== chain.id) {
    throw new Error(`Not connected to ${network} network`)
  }

  options.onTransactionStatusChange('confirm', {
    title: `Enable Spending ${formatDepositTokenSymbol(token)} on Derive`,
    contextLink: {
      href: HELP_ENABLE_SPENDING_URL,
      label: 'Why is this required?',
      target: '_blank',
    },
  })

  const data = await signToApproveGelatoBridge(
    walletClient,
    network,
    token,
    amount,
    isSponsored,
    maxFee
  )

  const tokenAddress = getDepositTokenAddress(network, token)

  const sender = walletClient.account.address

  const { gelatoSponsoredHelper, gelatoSelfPayingHelper } = getDepositContractAddresses(
    network,
    token
  )

  options.onTransactionStatusChange('confirm', {
    title: `Confirm Deposit ${formatDepositTokenBalance(amount, token)}`,
    context: `Deposits typically take ${getBridgeDurationEstimate(network)}.`,
    contextLearnMoreHref: HELP_BRIDGE_URL,
  })
  let signatureData
  if (isSponsored) {
    // Sponsored
    if (!gelatoSponsoredHelper) {
      throw new Error(`Missing gelatoSponsoredHelper contract for ${token} on ${network} network`)
    }
    signatureData = await gelatoRelay.getSignatureDataERC2771(
      {
        chainId: BigInt(chain.id),
        target: gelatoSponsoredHelper,
        data,
        user: sender,
      },
      walletClientToSigner(walletClient),
      ERC2771Type.SponsoredCall
    )
  } else {
    // Self-paying flow
    if (!gelatoSelfPayingHelper) {
      throw new Error(`Missing gelatoSelfPayingHelper contract for ${token} on ${network} network`)
    }
    const request: CallWithSyncFeeERC2771Request = {
      chainId: BigInt(chain.id),
      target: gelatoSelfPayingHelper,
      data,
      user: sender,
      feeToken: tokenAddress,
    }

    signatureData = await gelatoRelay.getSignatureDataERC2771(
      request,
      walletClientToSigner(walletClient),
      ERC2771Type.CallWithSyncFee
    )
  }
  const serializedSignatureData = JSON.stringify(signatureData, (key, value) =>
    typeof value === 'bigint' ? value.toString() : value
  )

  options.onTransactionStatusChange('in-progress', {
    title: `Depositing ${formatDepositTokenBalance(amount, token)}`,
  })

  const depositRes = await fetch('/api/deposit/gelato', {
    method: 'POST',
    body: JSON.stringify({
      signatureData: serializedSignatureData,
      // Note: post full amount for reference, grab amount less fees later
      amount: toHex(amount),
      network,
      token,
      isSponsored,
    }),
  })

  if (!depositRes || !depositRes.ok) {
    let errorMsg
    try {
      errorMsg = await depositRes.text()
    } catch {
      throw new Error('Something went wrong. Try again or contact support')
    }
    if (errorMsg === getTransactionDisabledMessage('kyt')) {
      throw new KytError(errorMsg)
    }
    throw new Error('Something went wrong. Try again or contact support')
  }

  const { taskId } = await depositRes.json()

  const txHash = await waitForRelayerTxHash(taskId)

  if (!options.skipCompleteStatus) {
    options.onTransactionStatusChange('complete', {
      txHash,
      chain,
      title: `Successfully Deposited ${formatDepositTokenBalance(amount, token)}`,
      context: `Your ${formatDepositTokenSymbol(
        token
      )} will be available for trading in ${getBridgeDurationEstimate(network)}.`,
    })
  }

  return { isNative: false, isSponsored: false, txHash, amount, fees: maxFee }
}

// Note: all gelato bridges must be sign-to-approve
// i.e. the deposit token must support permit, receive with auth, etc
const signToApproveGelatoBridge = async (
  walletClient: LyraWalletClient,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  isSponsored: boolean,
  maxFee: bigint
): Promise<`0x${string}`> => {
  switch (network) {
    case DepositNetwork.Ethereum:
      return signGelatoBridgeReceiveWithAuth(
        walletClient,
        network,
        token,
        amount,
        isSponsored,
        maxFee
      )
    case DepositNetwork.Base:
    case DepositNetwork.Arbitrum:
      return signGelatoBridgePermit(walletClient, network, token, amount, isSponsored, maxFee)
    case DepositNetwork.Optimism:
      throw new Error('Sign to approve not supported on Optimism network')
  }
}

// signReceiveWithAuth function is only available on Ethereum USDC
const signGelatoBridgeReceiveWithAuth = async (
  walletClient: LyraWalletClient,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  isSponsored: boolean,
  maxFee: bigint
): Promise<`0x${string}`> => {
  const publicClient = await getNetworkClient(network)
  if (publicClient.chain.id !== mainnetChain.id) {
    throw new Error('Not connected to supported network: Ethereum')
  }

  const now = (await publicClient.getBlock({ blockTag: 'latest' })).timestamp
  const validBefore = now + BigInt(SECONDS_IN_DAY)
  const nonce = keccak256(toHex(Math.floor(Math.random() * 1000000000000)))
  // Fetch EIP-712 fields
  const tokenAddress = getDepositTokenAddress(network, token)

  const [name, versionRes] = await publicClient.multicall({
    contracts: [
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'name',
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'version',
      },
    ],
  })
  if (name.status === 'failure' || (isMainnet && versionRes.status === 'failure')) {
    throw new Error('Error reading USDC contract')
  }

  const { socketDepositFastConnector, gelatoSelfPayingHelper, gelatoSponsoredHelper } =
    getDepositContractAddresses(network, token)

  const toAddress = isSponsored ? gelatoSponsoredHelper : gelatoSelfPayingHelper
  if (!toAddress) {
    throw new Error(
      `Missing ${
        isSponsored ? 'gelatoSponsoredHelper' : 'gelatoSelfPayingHelper'
      } contract for ${token} on Ethereum network`
    )
  }

  // Note: deposit subtracts up to maxFee
  // To ensure at least user-defined amount is deposited, add maxFee
  const amountWithMaxFee = isSponsored ? amount : amount + maxFee

  // Should only apply for Testnet
  const version = versionRes.result ?? '1'
  const signature = await walletClient.signTypedData({
    domain: {
      name: name.result,
      version: version,
      chainId: publicClient.chain.id,
      verifyingContract: tokenAddress,
    },
    primaryType: 'ReceiveWithAuthorization',
    types: {
      ReceiveWithAuthorization: [
        { name: 'from', type: 'address' },
        { name: 'to', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'validAfter', type: 'uint256' },
        { name: 'validBefore', type: 'uint256' },
        { name: 'nonce', type: 'bytes32' },
      ],
    },
    message: {
      from: walletClient.account.address,
      to: toAddress,
      value: amountWithMaxFee,
      nonce,
      validAfter: now,
      validBefore,
    },
  })
  const { r, s, v } = hexToSignature(signature)

  const receiveWithAuthStruct = {
    value: amountWithMaxFee,
    nonce,
    validAfter: now,
    validBefore,
    r,
    s,
    v: Number(v),
  }

  // Note: eth mainnet / testnet deposit wrapper only supports USDC
  if (isSponsored) {
    // Sponsored
    return encodeFunctionData({
      abi: sponsoredForwarderAbi,
      functionName: 'depositUSDCSocketBridge',
      args: [
        true /* isSCW */,
        SOCKET_BRIDGE_L2_GAS_LIMIT,
        socketDepositFastConnector,
        receiveWithAuthStruct,
      ],
    })
  } else {
    // Self-paying flow
    return encodeFunctionData({
      abi: selfPayingForwarderAbi,
      functionName: 'depositUSDCSocketBridge',
      args: [
        maxFee,
        true /* isSCW */,
        SOCKET_BRIDGE_L2_GAS_LIMIT,
        socketDepositFastConnector,
        receiveWithAuthStruct,
      ],
    })
  }
}

// Arbitrum and Base only - uses signed permit flow
const signGelatoBridgePermit = async (
  walletClient: LyraWalletClient,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  isSponsored: boolean,
  maxFee: bigint
): Promise<`0x${string}`> => {
  const publicClient = await getNetworkClient(network)
  if (walletClient.chain.id !== arbitrumChain.id && walletClient.chain.id !== baseChain.id) {
    throw new Error('Not connected to supported network: Arbitrum, Base')
  }

  const now = (await publicClient.getBlock()).timestamp
  const deadline = now + BigInt(SECONDS_IN_DAY)

  const tokenAddress = getDepositTokenAddress(network, token)
  const ownerAddress = walletClient.account.address

  const [nonceRes, nameRes, versionRes] = await publicClient.multicall({
    contracts: [
      {
        abi: erc20Abi,
        address: tokenAddress,
        functionName: 'nonces',
        args: [ownerAddress],
      },
      {
        abi: erc20Abi,
        address: tokenAddress,
        functionName: 'name',
      },
      {
        abi: erc20Abi,
        address: tokenAddress,
        functionName: 'version',
      },
    ],
  })
  if (nonceRes.status === 'failure' || nameRes.status === 'failure') {
    throw new Error('Error reading USDC contract')
  }

  const { socketDepositFastConnector, socketVault, gelatoSelfPayingHelper, gelatoSponsoredHelper } =
    getDepositContractAddresses(network, token)

  const spender = isSponsored ? gelatoSponsoredHelper : gelatoSelfPayingHelper
  if (!spender) {
    throw new Error(
      `Missing ${
        isSponsored ? 'gelatoSponsoredHelper' : 'gelatoSelfPayingHelper'
      } contract for ${token} on ${network}`
    )
  }

  // Note: deposit subtracts up to maxFee
  // To ensure at least user-defined amount is deposited, add maxFee
  const amountWithMaxFee = isSponsored ? amount : amount + maxFee

  const nonce = nonceRes.result
  const name = nameRes.result
  // Some token contracts on Arbitrum do not expose version()
  // All tokens on arbitrum are version 1 now and not likely to change soon
  const version = versionRes.result ?? '1'
  const signature = await walletClient.signTypedData({
    domain: {
      name,
      version,
      chainId: publicClient.chain.id,
      verifyingContract: tokenAddress,
    },
    primaryType: 'Permit',
    types: {
      Permit: [
        {
          name: 'owner',
          type: 'address',
        },
        {
          name: 'spender',
          type: 'address',
        },
        {
          name: 'value',
          type: 'uint256',
        },
        {
          name: 'nonce',
          type: 'uint256',
        },
        {
          name: 'deadline',
          type: 'uint256',
        },
      ],
    },
    message: {
      owner: ownerAddress,
      spender,
      value: amountWithMaxFee,
      nonce,
      deadline,
    },
  })
  const { r, s, v } = hexToSignature(signature)
  const signPermitStruct = {
    value: amountWithMaxFee,
    deadline,
    r,
    s,
    v: Number(v),
  }

  if (isSponsored) {
    // Sponsored
    return encodeFunctionData({
      abi: permitSponsoredForwarderAbi,
      functionName: 'depositGasless',
      args: [
        tokenAddress,
        socketVault,
        true /* toSCW */,
        SOCKET_BRIDGE_L2_GAS_LIMIT,
        socketDepositFastConnector,
        signPermitStruct,
      ],
    })
  } else {
    // Self-paying flow
    return encodeFunctionData({
      abi: permitSelfPayingForwarderAbi,
      functionName: 'depositGasless',
      args: [
        tokenAddress,
        socketVault,
        maxFee,
        true /* toSCW */,
        SOCKET_BRIDGE_L2_GAS_LIMIT,
        socketDepositFastConnector,
        signPermitStruct,
      ],
    })
  }
}

// returns all active collaterals for a network
export const getActiveDepositTokens = (network: DepositNetwork) => {
  const chainId = getChainForDepositNetwork(network).id.toString()
  const networkSocketAddresses = socketDepositContractAddresses[chainId]
  if (!networkSocketAddresses) {
    return []
  }
  const depositTokens = filterNulls(
    Object.keys(networkSocketAddresses).map((token) => coerce(DepositTokenId, token))
  )

  depositTokens.push(DepositTokenId.ETH)

  return depositTokens.filter((token) => depositTokenConfig[token].isActive)
}

// returns all active collaterals for a network with additional filtering
export const getSupportedDepositTokens = (
  network: DepositNetwork,
  user?: User
): DepositTokenId[] => {
  const depositTokens = getActiveDepositTokens(network)

  return depositTokens.filter((tokenId) => {
    const allowlist = depositTokenConfig[tokenId].allowlist
    return !allowlist || (user && (allowlist.has(user.ownerAddress) || allowlist.has(user.address)))
  })
}

export const getActiveWithdrawTokens = (network: DepositNetwork): DepositTokenId[] => {
  return filterNulls(
    (
      Object.keys(socketWithdrawContractAddresses).filter((token) => {
        const chainId = getChainForDepositNetwork(network).id.toString()
        return !!socketWithdrawContractAddresses[token as DepositTokenId]?.connectors[chainId]?.FAST
      }) as DepositTokenId[]
    ).map((token) => coerce(DepositTokenId, token))
  ).filter((token) => depositTokenConfig[token].isActive)
}

export const getIsRelayerTaskPending = (relayerStatus: RelayerStatus) => {
  return !GELATO_MESSAGE_COMPLETE_STATUSES.includes(relayerStatus)
}

export const getIsBridgeTxPending = (tx: BridgeTransaction) => {
  const bridgeStatus = tx.type === 'deposit' ? tx.bridgeToLyra.status : tx.bridgeFromLyra.status
  const isSocketPending =
    // Check status is a Socket status for migration from old combined status
    SOCKET_MESSAGE_STATUSES.includes(bridgeStatus) &&
    !SOCKET_MESSAGE_COMPLETE_STATUSES.includes(bridgeStatus)
  const hasBridgeFailed = BRIDGE_FAILED_STATUSES.includes(bridgeStatus)
  const relayerTaskStatus = tx.type === 'deposit' ? tx.bridgeToLyra.gelato?.status : undefined
  const hasGelatoFailed = relayerTaskStatus
    ? GELATO_MESSAGE_FAILED_STATUSES.includes(relayerTaskStatus)
    : false
  const isGelatoPending = relayerTaskStatus ? getIsRelayerTaskPending(relayerTaskStatus) : false

  return {
    isPending: !hasGelatoFailed && !hasBridgeFailed && (isGelatoPending || isSocketPending),
    isSocketPending,
    isGelatoPending,
  }
}

export const getOutboundTxHash = (tx: BridgeTransaction) =>
  tx.type === 'deposit' ? tx.bridgeToLyra.srcTxHash : tx.bridgeFromLyra.lyraTxHash

export const getTxBridgeStatus = (
  tx: BridgeTransaction
): { status: BridgeTransactionStatus; message?: string } => {
  const { isPending, isSocketPending, isGelatoPending } = getIsBridgeTxPending(tx)
  // Pending
  if (isPending) {
    return {
      status: 'pending',
      message: isGelatoPending
        ? 'Relayer transaction is in progress'
        : isSocketPending
        ? 'Bridge transaction is in progress'
        : undefined,
    }
  }
  // Gelato failure
  if (tx.type === 'deposit' && tx.bridgeToLyra.gelato?.status === TaskState.Cancelled) {
    return {
      status: 'cancelled',
      message: 'Transaction cancelled by relayer. No fees have been charged',
    }
  } else if (tx.type === 'deposit' && tx.bridgeToLyra.gelato?.status === TaskState.ExecReverted) {
    return {
      status: 'reverted',
      message: 'Transaction reverted on-chain',
    }
  }
  // Bridge failure
  const bridgeStatus = tx.type === 'deposit' ? tx.bridgeToLyra.status : tx.bridgeFromLyra.status
  if (bridgeStatus === 'EXECUTION_FAILURE' || bridgeStatus === 'INBOUND_REVERTING') {
    return { status: 'reverted', message: 'Transaction reverted on-chain' }
  }

  return { status: 'completed' }
}

export const getDepositContractAddresses = (
  network: DepositNetwork,
  token: DepositTokenId
): BridgeContractAddresses => {
  const chain = getChainForDepositNetwork(network)
  // WETH and ETH share the same configs
  const socketToken = formatSocketDepositTokenSymbol(token)

  // These must be defined for bridge
  const socketAddresses = socketDepositContractAddresses[chain.id.toString()][socketToken]
  const externalAddresses = externalDepositContractAddresses[network]

  if (!socketAddresses || !externalAddresses) {
    throw new Error(`Bridge not supported for ${token} on ${network} network`)
  }

  const socketConnectors =
    '957' in socketAddresses.connectors
      ? // Mainnet
        socketAddresses.connectors['957']
      : // Testnet
        socketAddresses.connectors['901']

  return {
    ...externalAddresses,
    isNewBridge: socketAddresses.isNewBridge ?? false,
    tokenAddress: token === DepositTokenId.ETH ? ETH_ADDRESS : socketAddresses.NonMintableToken,
    socketDepositFastConnector: socketConnectors.FAST,
    socketVault: socketAddresses.Vault,
  }
}

export const getWithdrawContractAddresses = (
  network: DepositNetwork,
  token: TokenId
): BridgeWithdrawContractAddresses => {
  const socketToken = formatSocketTokenSymbol(token)
  const socketAddresses = socketWithdrawContractAddresses[socketToken]
  const externalAddresses = bridgeWithdrawContractAddresses[token]
  const chainId = getChainForDepositNetwork(network).id.toString()
  if (!socketAddresses || !externalAddresses) {
    throw new Error(`Bridge not supported for ${token} on Derive`)
  }
  const networkConnectors = socketAddresses.connectors[chainId]
  const withdrawHookAddresses: BridgeWithdrawContractHookAddresses = socketAddresses.isNewBridge
    ? {
        isNewBridge: true,
        shareHandlerWithdrawHook: socketAddresses.LyraTSAShareHandlerDepositHook,
      }
    : { isNewBridge: false, shareHandlerWithdrawHook: undefined }

  return {
    // Address for controller on Lyra Chain
    // Note: this determines destination chain and destination token for withdraws
    // e.g. for USDC vs USDC.e
    lyraTokenAddress: socketAddresses.MintableToken,
    socketController: socketAddresses.Controller,
    socketWithdrawFastConnector: networkConnectors.FAST,
    withdrawHelper: externalAddresses.withdrawHelper,
    ...withdrawHookAddresses,
  }
}

export const fetchBridgeFromLyraChainTxs = async ({
  // !!SUPER IMPORTANT!!
  // sender is always smart contract wallet address
  // receiver is typically owner address
  // FAILURE TO SET THE RECEIVER ADDRESS CORRECTLY WILL RESULT IN LOSS OF FUNDS
  receiver,
  withdrawNetwork,
  token,
  withdrawToken,
  amount,
}: {
  receiver: Address
  withdrawNetwork: DepositNetwork
  token: TokenId
  withdrawToken: DepositTokenId
  amount: bigint
}): Promise<BatchUserOperationCallData> => {
  const { socketController, socketWithdrawFastConnector, withdrawHelper } =
    getWithdrawContractAddresses(withdrawNetwork, token)

  if (!socketWithdrawFastConnector) {
    throw new Error(`Withdraws not supported for ${withdrawToken} to ${withdrawNetwork}`)
  }

  const txs: BatchUserOperationCallData = []
  const withdrawalGasLimit = await fetchBridgeWithdrawGasLimit(receiver, withdrawNetwork)

  const tokenAddress = getLyraTokenAddress(token)

  console.debug('approving for withdrawal')
  txs.push({
    target: tokenAddress,
    data: encodeFunctionData({
      abi: erc20Abi,
      functionName: 'approve',
      args: [withdrawHelper, MAX_INT],
    }),
  })

  console.debug('withdraw')
  txs.push({
    target: withdrawHelper,
    data: encodeFunctionData({
      abi: withdrawalHelperAbi,
      functionName: 'withdrawToChain',
      args: [
        tokenAddress,
        amount,
        // !!SUPER IMPORTANT!!
        // receiver is always owner address on destination chain
        // FAILURE TO SET THE RECEIVER ADDRESS CORRECTLY WILL RESULT IN LOSS OF FUNDS
        receiver,
        socketController,
        socketWithdrawFastConnector,
        withdrawalGasLimit,
      ],
    }),
  })

  return txs
}

export const getBridgeDurationEstimate = (network: DepositNetwork): string => {
  switch (network) {
    case DepositNetwork.Arbitrum:
    case DepositNetwork.Optimism:
    case DepositNetwork.Base:
      return '1-2 minutes'
    case DepositNetwork.Ethereum:
      return '5-10 minutes'
  }
}

const fetchBridgeWithdrawGasLimit = async (
  ownerAddress: Address,
  withdrawNetwork: DepositNetwork
): Promise<bigint> => {
  const networkClient = await getNetworkClient(withdrawNetwork)
  switch (withdrawNetwork) {
    case DepositNetwork.Arbitrum: {
      // Note: arbitrum gas depends on L1 gas prices, must be calculated relative to erc20 transfer
      // gas for erc20 transfer
      const estGasUsed = await networkClient.estimateGas({ account: ownerAddress })
      return BigInt(5) * estGasUsed
    }
    case DepositNetwork.Ethereum:
    case DepositNetwork.Optimism:
    case DepositNetwork.Base:
      return BigInt(SOCKET_BRIDGE_L2_GAS_LIMIT)
  }
}

export const fetchWithdrawFee = async (
  ownerAddress: Address,
  network: DepositNetwork,
  token: TokenId,
  withdrawToken: DepositTokenId
): Promise<bigint> => {
  const { withdrawHelper, socketController, socketWithdrawFastConnector } =
    getWithdrawContractAddresses(network, token)

  if (!socketWithdrawFastConnector) {
    throw new Error(`Withdraws not supported for ${withdrawToken} to ${network}`)
  }

  const gasLimit = await fetchBridgeWithdrawGasLimit(ownerAddress, network)
  const tokenAddress = getLyraTokenAddress(token)
  return await lyraClient.readContract({
    address: withdrawHelper,
    abi: withdrawalHelperAbi,
    functionName: 'getFeeInToken',
    args: [tokenAddress, socketController, socketWithdrawFastConnector, gasLimit],
  })
}

const fetchBridgeDepositGasLimit = async (
  ownerAddress: Address,
  network: DepositNetwork,
  isNative: boolean
): Promise<bigint> => {
  const networkClient = await getNetworkClient(network)
  switch (network) {
    case DepositNetwork.Base:
    case DepositNetwork.Arbitrum: {
      // Note: arbitrum gas depends on L1 gas prices, must be calculated relative to erc20 transfer
      // gas for erc20 transfer
      const estGasUsed = await networkClient.estimateGas({ account: ownerAddress })
      // Gelato: 5.3x erc20 transfer
      // Native: 4.5x erc20 transfer
      return (BigInt(isNative ? 45 : 53) / BigInt(10)) * estGasUsed
    }
    case DepositNetwork.Ethereum:
      // 250K gas cost + Gelato 150K overhead.
      return BigInt(isNative ? 250_000 : 400_000)
    case DepositNetwork.Optimism:
      if (!isNative) {
        throw new Error('Gelato deposits not supported on Optimism network')
      }
      // 180k gas cost
      return BigInt(180_000)
  }
}

// Fetches Socket L2 gas fee for minting tokens on Lyra Chain
// Used for total fee estimation
export const fetchSocketBridgeFee = async (
  network: DepositNetwork,
  token: DepositTokenId
): Promise<bigint> => {
  const networkClient = await getNetworkClient(network)

  const {
    socketVault,
    socketDepositFastConnector,
    isNewBridge: isNew,
  } = getDepositContractAddresses(network, token)
  const gasLimit = BigInt(SOCKET_BRIDGE_L2_GAS_LIMIT)

  if (isNew) {
    return networkClient.readContract({
      abi: vaultAbi,
      address: socketVault,
      functionName: 'getMinFees',
      args: [socketDepositFastConnector, gasLimit, BigInt(161)],
    })
  } else {
    return networkClient.readContract({
      abi: vaultOldAbi,
      address: socketVault,
      functionName: 'getMinFees',
      args: [socketDepositFastConnector, gasLimit],
    })
  }
}

const getRequiresGasLimitL1 = (network: DepositNetwork) => {
  switch (network) {
    case DepositNetwork.Arbitrum:
    case DepositNetwork.Ethereum:
      return false
    // OP Stack networks require gasLimitL1 parameter when estimating Gelato fees
    case DepositNetwork.Base:
    case DepositNetwork.Optimism:
      return true
  }
}

export const fetchEstimateGelatoBridgeFee = async (
  ownerAddress: Address,
  network: DepositNetwork,
  token: DepositTokenId
): Promise<bigint> => {
  if (isTestnet) {
    // sponsored only on testnet
    return BigInt(0)
  }

  const gasLimit = await fetchBridgeDepositGasLimit(ownerAddress, network, false /* isNative */)

  const tokenAddress = getDepositTokenAddress(network, token)

  const estimatedFee = await gelatoRelay.getEstimatedFee(
    BigInt(getChainForDepositNetwork(network).id),
    tokenAddress,
    gasLimit,
    false,
    getRequiresGasLimitL1(network) ? BigInt(200_000) : undefined
  )

  if (network === DepositNetwork.Arbitrum || network === DepositNetwork.Base) {
    // Gelato recommendation to 2x estimated fee on Arbitrum/Base
    return estimatedFee * BigInt(2)
  } else {
    return estimatedFee
  }
}

export const fetchEstimateNativeBridgeGasFee = async (
  ownerAddress: Address,
  network: DepositNetwork
): Promise<bigint> => {
  const networkClient = await getNetworkClient(network)
  const [feePerGas, gasLimit] = await Promise.all([
    networkClient.estimateFeesPerGas({
      // Note: arbitrum does not support eip1559
      type: network === DepositNetwork.Arbitrum ? 'legacy' : 'eip1559',
    }),
    fetchBridgeDepositGasLimit(ownerAddress, network, true /* isNative */),
  ])
  const { maxFeePerGas, maxPriorityFeePerGas, gasPrice } = feePerGas
  const gasFee =
    maxFeePerGas && maxPriorityFeePerGas
      ? (maxFeePerGas + maxPriorityFeePerGas) * gasLimit
      : // Handle legacy fee estimation, i.e. for Arbitrum
      gasPrice
      ? gasPrice * gasLimit
      : BigInt(0)

  // Add 10% overhead to native gas fee
  return (gasFee * BigInt(110)) / BigInt(100)
}

export const fetchSocketMessageStatusFromTx = async (txHash: string, srcChainId: string) => {
  const queryParams = formatQueryParams({ srcChainSlug: srcChainId, srcTxHash: txHash })
  const res = await fetch(new URL(`?${queryParams}`, SOCKET_TX_API_URL), { method: 'GET' })
  return (await res.json()) as SocketStatusResponse
}

export const fetchSocketMessageStatusFromId = async (messageId: string) => {
  const queryParams = formatQueryParams({ messageId })
  const res = await fetch(new URL(`?${queryParams}`, SOCKET_MESSAGE_API_URL), { method: 'GET' })
  return (await res.json()) as SocketMessageIdStatusResponse
}

// Calculate max deposit amount given a fee quote with buffer applied
export const getDepositAmountWithFees = (
  depositAmountInput: bigint,
  balance: bigint,
  selectedToken: DepositTokenId,
  selectedNetwork: DepositNetwork,
  depositQuote: DepositQuote | undefined
) => {
  // Fee is applied if:
  //  - feeToken matches deposit token AND
  //    - deposit is unsponsored OR
  //    - deposit is a native deposit (i.e. not routed through Gelato)
  const feeBN =
    depositQuote &&
    depositQuote.feeToken === selectedToken &&
    (depositQuote.isNative || !depositQuote.isSponsored)
      ? depositQuote.fee
      : BigInt(0)

  // add 2.5% buffer to fee
  const feeWithBuffer = (feeBN * BigInt(1025)) / BigInt(1000)
  const sponsoredDepositThreshold = getGelatoMinDepositForSponsorship(
    selectedNetwork,
    selectedToken
  )

  // only add fees to deposit amount when fee token == selected token and deposit is NOT sponsored
  const depositAmountWithFees = depositQuote ? depositAmountInput + feeWithBuffer : undefined

  // when fee is paid in deposit token, subtract fees from balance for max deposit amount
  // ignore fee if Gelato is available + balance is enough for sponsored deposit
  const maxDepositAmount =
    balance >= sponsoredDepositThreshold &&
    depositQuote &&
    !depositQuote.isNative &&
    (depositQuote.isSponsored || depositQuote.notSponsoredReason === 'min-amount')
      ? balance
      : balance - feeWithBuffer

  return {
    depositAmountWithFees,
    maxDepositAmount,
  }
}
